<template>
  <a-card class="auth-account-container">
    <div class="header-actions">
      <a-space>
        <a-button @click="backUp">
          <template #icon><left-outlined /></template>
          返回
        </a-button>
      </a-space>
    </div>

    <a-row :gutter="[16, 16]">
      <!-- Left Side Panel -->
      <a-col :span="6">
        <a-card :bordered="false" class="left-panel">
          <a-tabs v-model:activeKey="state.leftActiveKey">
            <a-tab-pane key="user" :tab="userName">
              <div class="search-bar">
                <a-input-search
                  v-model:value="name"
                  placeholder="请输入名称搜索"
                  @search="getProductList"
                  @keyup.enter="getProductList"
                  allowClear
                >
                  <template #prefix>
                    <search-outlined />
                  </template>
                </a-input-search>
              </div>
              <div class="account-list">
                <a-empty v-if="!accountList.length" />
                <a-list v-else :data-source="accountList" :split="false">
                  <template #renderItem="{ item }">
                    <a-list-item
                      :class="['account-item', { active: item.active }]"
                      @click="setAccount(item)"
                    >
                      <div class="account-info">
                        <div class="account-name">{{ item.name }}</div>
                        <div class="account-type">
                          {{ item.appCategory || item.appCode }}
                        </div>
                      </div>
                      <right-outlined />
                    </a-list-item>
                  </template>
                </a-list>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-col>

      <!-- Right Side Panel -->
      <a-col :span="18">
        <a-card :bordered="false" class="right-panel">
          <a-spin :spinning="state.loading">
            <a-tabs
              v-model:activeKey="state.rightActiveKey"
              @change="changeTab"
              class="auth-tabs"
            >
              <a-tab-pane
                key="warehouse"
                v-if="appInfo.appCategory == 'WMS'"
                tab="授权到仓库"
              >
                <div class="authTreeStyle">
                  <a-spin :spinning="treeLoading">
                    <p class="funClass">仓库/货主</p>
                    <a-row>
                      <a-tree
                        v-model:expandedKeys="warehouseTree.menuExpandedKeys"
                        v-model:checkedKeys="warehouseTree.menuCheckedKeys"
                        @check="setWarehouseKeys"
                        checkable
                        :tree-data="warehouseTree.warehouseTreeList"
                        :field-names="warehouseFieldNames"
                      ></a-tree>
                    </a-row>
                  </a-spin>
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="settlementBu"
                v-if="hasFMSService"
                tab="授权到结算组织"
              >
                <div class="authTreeStyle">
                  <a-spin :spinning="treeLoading">
                    <p class="funClass">结算组织</p>
                    <a-row>
                      <a-tree
                        v-model:expandedKeys="
                          settlementDepartmentTree.expandedKeys
                        "
                        v-model:checkedKeys="
                          settlementDepartmentTree.checkedKeys
                        "
                        @check="setSettlementDepartmentKeys"
                        checkable
                        :tree-data="settlementDepartmentTree.departmentTreeList"
                        :field-names="departmentFieldNames"
                      ></a-tree>
                    </a-row>
                  </a-spin>
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="department"
                v-if="hasShopService"
                tab="授权到业务组"
              >
                <div class="authTreeStyle">
                  <a-spin :spinning="treeLoading">
                    <p class="funClass">业务组</p>
                    <a-row>
                      <a-tree
                        v-model:expandedKeys="departmentTree.expandedKeys"
                        v-model:checkedKeys="departmentTree.checkedKeys"
                        @check="setDepartmentKeys"
                        checkable
                        :tree-data="departmentTree.departmentTreeList"
                        :field-names="departmentFieldNames"
                      ></a-tree>
                    </a-row>
                  </a-spin>
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="auth"
                v-if="appInfo.appCode != 'SCM'"
                tab="授权到权限"
              >
                <div class="authTreeStyle">
                  <a-spin :spinning="treeLoading">
                    <a-row
                      ><a-col :span="8">
                        <p class="funClass">PC菜单权限</p>
                        <a-tree
                          v-model:expandedKeys="menuTree.expandedKeys"
                          v-model:checkedKeys="menuTree.checkedKeys"
                          @check="setMenuTreeKeys"
                          checkable
                          :tree-data="menuTree.treeList"
                          :field-names="funFieldNames"
                        ></a-tree>
                      </a-col>
                      <a-col :span="8">
                        <p class="funClass">功能权限</p>
                        <a-tree
                          v-model:expandedKeys="funTree.expandedKeys"
                          v-model:checkedKeys="funTree.checkedKeys"
                          @check="setFunTreeKeys"
                          checkable
                          :tree-data="funTree.treeList"
                          :field-names="funFieldNames"
                        ></a-tree>
                      </a-col>

                      <a-col :span="8" v-if="appInfo.appCode == 'ULTRA_WMS'">
                        <p class="funClass">PDA菜单权限</p>
                        <a-tree
                          v-model:expandedKeys="pdaMenuTree.expandedKeys"
                          v-model:checkedKeys="pdaMenuTree.checkedKeys"
                          @check="setPdaMenuTreeKeys"
                          checkable
                          :tree-data="pdaMenuTree.treeList"
                          :field-names="funFieldNames"
                        ></a-tree>
                      </a-col>
                    </a-row>
                  </a-spin>
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="role"
                v-if="appInfo.appCode != 'SCM'"
                tab="授权到角色"
              >
                <div class="header">
                  <a-input
                    style="width: 180px; margin: 0 15px"
                    v-model:value="state.name"
                    placeholder="请输入角色名称"
                    @keyup.enter="getRoleList"
                    allowClear
                  />
                  <a-button type="primary" @click="getRoleList">
                    查询
                  </a-button>
                </div>
                <a-table
                  :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onSelectChange,
                  }"
                  :columns="state.columns"
                  rowKey="id"
                  :scroll="{ y: 500 }"
                  :data-source="roleList"
                  :pagination="false"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'status'">
                      <span>
                        <a-switch disabled v-model:checked="record.status" />
                      </span>
                    </template>
                  </template>
                </a-table>
              </a-tab-pane>
              <a-tab-pane
                key="supplier"
                v-if="appInfo.appCode == 'SCM'"
                tab="供应商"
              >
                <!-- <div class="header">
                <a-input
                  style="width: 180px; margin: 0 15px"
                  v-model:value="state.name"
                  placeholder="请输入供应商名称"
                  @keyup.enter="getRoleList"
                  allowClear
                />
                <a-button type="primary" @click="getRoleList">查询</a-button>
              </div> -->
                <a-table
                  :row-selection="{
                    selectedRowKeys: supplierRowKeys,
                    onChange: onSelectSupplier,
                  }"
                  :columns="state.supplierList"
                  rowKey="id"
                  :scroll="{ y: 500 }"
                  :data-source="supplierList"
                  :pagination="false"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'status'">
                      <span>
                        <a-switch disabled v-model:checked="record.status" />
                      </span>
                    </template>
                  </template>
                </a-table>
              </a-tab-pane>
            </a-tabs>
            <div class="footer-actions">
              <a-button type="primary" :loading="saveLoading" @click="save">
                保存
              </a-button>
            </div>
          </a-spin>
        </a-card>
      </a-col>
    </a-row>
  </a-card>
</template>

<script setup>
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue"
import { message } from "ant-design-vue"
import { onActivated, ref, computed } from "vue"
import { useRoute, useRouter } from "vue-router"

const route = useRoute()
const router = useRouter()
const store = useStore()

const state = ref({
	loading: true,
	leftActiveKey: "user",
	rightActiveKey: "department",
	name: "",
	columns: [
		{ title: "角色名称", dataIndex: "name", key: "name" },
		{ title: "角色ID", dataIndex: "code", key: "code", width: 100 },
		{ title: "角色状态", dataIndex: "status", key: "status" },
		{ title: "角色描述", dataIndex: "note", key: "note" },
	],
	supplierList: [
		{
			title: "供应商名称",
			dataIndex: "supplierName",
			key: "supplierName",
			width: 100,
		},
		{
			title: "供应商编码",
			dataIndex: "supplierCode",
			key: "supplierCode",
			width: 100,
		},
		{ title: "合作方式", dataIndex: "bizMode", key: "bizMode", width: 100 },
		{ title: "供应商国家", dataIndex: "country", key: "country", width: 120 },
		{ title: "状态", dataIndex: "status", key: "status", width: 80 },
	],
})

const userName = ref("")
const name = ref("")
const accountList = ref([])
const userId = ref("")
const treeLoading = ref(false)
const appInfo = ref("") //记录左边选择的应用是谁

const hasShopService = computed(() => {
	return appInfo.value.subAppList?.some((item) => item.subAppCode === "SHOP")
})
const hasFMSService = computed(() => {
	return appInfo.value.subAppList?.some((item) => item.subAppCode === "FMS")
})
onActivated(async () => {
	clear()
	clearTabLoad()
	getResourceMap()
	await getProductList()
	state.value.page = route.query.page || ""
	userId.value = route.query.userId
	userName.value = route.query.userName
	configDetail()
})
const clear = () => {
	state.value.loading = true
	state.value.leftActiveKey = "user"
	state.value.rightActiveKey = "department"
	warehouseTree.value.menuExpandedKeys = []
	warehouseTree.value.menuCheckedKeys = []
	warehouseTree.value.warehouseTreeList = []
	warehouseTree.value.menuHalfKeys = []
	departmentTree.value.expandedKeys = []
	departmentTree.value.checkedKeys = []
	departmentTree.value.departmentTreeList = []
	departmentTree.value.halfKeys = []
	settlementDepartmentTree.value.expandedKeys = []
	settlementDepartmentTree.value.checkedKeys = []
	settlementDepartmentTree.value.departmentTreeList = []
	settlementDepartmentTree.value.halfKeys = []
	funTree.value.expandedKeys = []
	funTree.value.checkedKeys = []
	funTree.value.treeList = []
	funTree.value.halfKeys = []
	menuTree.value.expandedKeys = []
	menuTree.value.checkedKeys = []
	menuTree.value.treeList = []
	menuTree.value.halfKeys = []
	pdaMenuTree.value.expandedKeys = []
	pdaMenuTree.value.checkedKeys = []
	pdaMenuTree.value.treeList = []
	pdaMenuTree.value.halfKeys = []
}

const configDetail = () => {
	axios.post(api.configDetail, { userId: userId.value }).then((res) => {
		config.value = []
		res.configList.forEach((item) => {
			const obj = {
				merchantId: item.merchantId,
				userId: item.userId,
				appCode: item.merchantApp.appCode,
				merchantAppId: item.merchantApp.id,
				userSettlementDepartmentList: [], //新增结算组织字段
				userDepartmentList: [],
				userWarehouseList: [],
				userAuthType: "",
				userResourceList: [],
				userRoleList: [],
				funList: [], //功能列表
				menuList: [], //菜单列表
				pdaMenuList: [], //菜单列表
				userFunList: [], //功能格式化好的列表
				userMenuList: [], //菜单格式化好的列表
				allFunKeys: [], //功能勾选的列表
				allMenuKeys: [], //功能勾选的列表
				pdaAllMenuKeys: [], //功能勾选的列表
			}
			//店铺和业务组的数据
			if (item.departmentList?.length) {
				//console.log("业务组", item.departmentList)
				item.departmentList.forEach((department) => {
					obj.userDepartmentList.push({
						departmentId: String(department.id),
						departmentType: department.type,
						allShop: department.allShop,
						shopUniqueVOList:
							department.shopList?.map((otem) => {
								return {
									shopCode: String(otem.uniqueCode),
									code: String(otem.code),
									shopName: String(otem.name),
									platform: otem.platform,
								}
							}) || [],
					})
				})
			} else {
				obj.userDepartmentList = []
			}
			//结算组织的数据
			if (item.settlementDepartmentList?.length) {
				//console.log("结算组织", item.settlementDepartmentList)
				item.settlementDepartmentList.forEach((department) => {
					obj.userSettlementDepartmentList.push({
						departmentId: String(department.id),
						departmentType: department.type,
						allShop: department.allShop,
						shopUniqueVOList:
							department.shopList?.map((otem) => {
								return {
									shopCode: String(otem.uniqueCode),
									code: String(otem.code),
									shopName: String(otem.name),
									platform: otem.platform,
								}
							}) || [],
					})
				})
			} else {
				obj.userSettlementDepartmentList = []
			}
			//仓库和货主的数据
			if (item.warehouseList?.length) {
				//console.log("仓库列表", item.warehouseList)
				item.warehouseList.forEach((warehouse) => {
					obj.userWarehouseList.push({
						warehouseCode: warehouse.code,
						wmsAppCode: "ULTRA_WMS",
						allShipper: warehouse.allShipper,
						shipperCodeList:
							warehouse.shipperList?.map((otem) => {
								return String(otem.code)
							}) || [],
					})
				})
			} else {
				obj.userWarehouseList = []
			}
			if (item.resourceList?.length) {
				//console.log("资源", item.resourceList)
				//把这个放到保存的时候在处理
				item.resourceList.forEach((resource) => {
					if (resource.type === "FUNC") {
						obj.funList.push(resource)
					} else if (resource.terminal === "PDA") {
						obj.pdaMenuList.push(resource)
					} else {
						obj.menuList.push(resource)
					}
				})

				funTree.value.halfKeys = []
				funTree.value.checkedKeys = []
				setFunCheckKeys(obj.funList)
				//console.log(funTree.value)

				obj.userFunList = [...funTree.value.halfKeys, ...funTree.value.checkedKeys].map((item) => {
					return {
						resourceId: item,
					}
				})

				obj.allFunKeys = [...funTree.value.halfKeys, ...funTree.value.checkedKeys]
				menuTree.value.halfKeys = []
				menuTree.value.checkedKeys = []
				setMeunCheckKeys(obj.menuList)
				//console.log(menuTree.value)
				obj.userMenuList = [...menuTree.value.halfKeys, ...menuTree.value.checkedKeys].map((item) => {
					return {
						resourceId: item,
					}
				})
				obj.allMenuKeys = [...menuTree.value.halfKeys, ...menuTree.value.checkedKeys]
				pdaMenuTree.value.halfKeys = []
				pdaMenuTree.value.checkedKeys = []
				setPdaMeunCheckKeys(obj.pdaMenuList)
				//console.log(pdaMenuTree.value)
				obj.pdaUserMenuList = [...pdaMenuTree.value.halfKeys, ...pdaMenuTree.value.checkedKeys].map((item) => {
					return {
						resourceId: item,
					}
				})
				obj.pdaAllMenuKeys = [...pdaMenuTree.value.halfKeys, ...pdaMenuTree.value.checkedKeys]
			} else {
				obj.userResourceList = []
			}
			if (item.roleList?.length) {
				//console.log("角色", item.roleList)
				obj.userRoleList = item.roleList.map((item) => {
					return { roleId: String(item.id) }
				})
			} else {
				obj.userRoleList = []
			}
			if (item.supplierList?.length) {
				const value = item.supplierList.map((item) => {
					return String(item.id)
				})
				supplierRowKeys.value = value
				userSupplierList.value = value.map((item) => {
					return {
						supplierId: item,
					}
				})
			}

			config.value.push(obj)
		})
		//console.log("------------------------config--start-----------------------")
		//console.log(config.value)
		//console.log("------------------------config--end-----------------------")

		if (config.value.length !== accountList.value.length) {
			const arr = config.value.map((item) => {
				return String(item.merchantAppId)
			})

			const arrAll = accountList.value.map((item) => {
				return String(item.id)
			})
			const arrCha = arrAll.filter((item) => {
				if (arr.indexOf(item) === -1) {
					return item
				}
			})
			arrCha.forEach((item) => {
				const appCode = accountList.value.find((elem) => String(elem.id) === item)?.appCode
				const obj = {
					merchantId: store.getters["user/merchantId"],
					userId: userId.value,
					merchantAppId: item,
					appCode,
					userDepartmentList: [],
					userSettlementDepartmentList: [],
					userWarehouseList: [],
					userAuthType: "",
					userResourceList: [],
					userRoleList: [],
					funList: [], //功能列表
					menuList: [], //菜单列表
					pdaMenuList: [], //菜单列表
					userFunList: [], //功能格式化好的列表
					userMenuList: [], //菜单格式化好的列表
					allFunKeys: [], //功能勾选的列表
					allMenuKeys: [], //功能勾选的列表
					pdaAllMenuKeys: [], //功能勾选的列表
				}
				config.value.push(obj)
			})
		}

		setAccount(appInfo.value)
	})
}

//请求左侧产品列表
const getProductList = async () => {
	const res = await axios.post(api.getMerchantAppList, {
		appResource: "AUTH_SYSTEM",
		merchantId: store.getters["user/merchantId"],
		name: "",
		page_no: 1,
		page_size: 1000,
	})
	//console.log("左侧应用...", res)
	accountList.value = res.list
	appInfo.value = accountList.value[0]
	config.value = []
	accountList.value.forEach((item) => {
		config.value.push({
			merchantId: store.getters["user/merchantId"],
			userId: userId.value,
			merchantAppId: String(item.id),
			appCode: item.appCode,
			userDepartmentList: [],
			userSettlementDepartmentList: [],
			userWarehouseList: [],
			userAuthType: "",
			userResourceList: [],
			userRoleList: [],
		})
	})
}
const setAccount = (value) => {
  clear()
	// console.log("左侧应用", value)
	accountList.value.forEach((item) => {
		if (String(item.id) === String(value.id)) {
			appInfo.value = value
			item.active = true
			if (appInfo.value.appCategory === "WMS") {
				state.value.rightActiveKey = "warehouse"
			} else if (hasShopService.value) {
				state.value.rightActiveKey = "department"
			} else if (appInfo.value.appCode === "SCM") {
				state.value.rightActiveKey = "supplier"
			} else if (hasFMSService.value) {
				state.value.rightActiveKey = "settlementBu"
			} else {
				state.value.rightActiveKey = "auth"
			}
			clearTabLoad()
			changeTab(state.value.rightActiveKey)
		} else {
			item.active = false
		}
	})
}

const clearTabLoad = () => {
	tabLoadStatus.value = {
		auth: false,
		department: false,
		settlementBu: false,
		warehouse: false,
		role: false,
		supplier: false,
	}
}

//角色功能
const roleList = ref([])
const selectedRowKeys = ref([])
const getRoleList = async () => {
	try {
		const res = await axios.post(api.roleList, {
			merchantId: store.getters["user/merchantId"],
			authSysCode: appInfo.value.authSysCode,
			name: state.value.name,
			status: "",
		})
		res.forEach((item) => {
			item.id = String(item.id)
		})
		roleList.value = res
		config.value.forEach((item) => {
			if (String(item.merchantAppId) === String(appInfo.value.id)) {
				//console.log(item.userRoleList)

				selectedRowKeys.value = item.userRoleList.map((item) => {
					return item.roleId
				})
			}
		})
	} catch (error) {
		console.error("Failed to get role list:", error)
		message.error("获取角色列表失败")
	}
}
const supplierList = ref([])
const getSupplierList = async () => {
	try {
		state.value.loading = true
		const res = await axios.post(api.supplierList, {
			merchantId: store.getters["user/merchantId"],
			merchantAppId: appInfo.value.id,
			// status: "",
			all: true,
		})
		res.forEach((item) => {
			item.id = String(item.id)
			item.key = item.id
		})
		supplierList.value = res
		config.value.forEach((item) => {
			if (String(item.merchantAppId) === String(appInfo.value.id)) {
				//console.log(item.userRoleList)

				selectedRowKeys.value = item.userRoleList.map((item) => {
					return item.roleId
				})
			}
		})
	} catch (error) {
		console.error("Failed to get supplier list:", error)
		message.error("获取供应商列表失败")
	}
}
const supplierRowKeys = ref([])
const userSupplierList = ref([])
const onSelectSupplier = (value) => {
	//console.log(value)
	supplierRowKeys.value = value
	userSupplierList.value = value.map((item) => {
		return {
			supplierId: item,
		}
	})
	config.value.forEach((item) => {
		if (String(item.merchantAppId) === String(appInfo.value.id)) {
			item.userSupplierList = userSupplierList.value
		}
	})
	//console.log(config.value)
}
const tabLoadStatus = ref({
	auth: false,
	department: false,
	settlementBu: false,
	warehouse: false,
	role: false,
	supplier: false,
})
const changeTab = (value) => {
	// 如果该 tab 已经加载过数据，直接返回
	if (tabLoadStatus.value[value]) {
		return
	}

	state.value.loading = true
	switch (value) {
		case "auth":
			Promise.all([getFunList(), getMenuList()]).finally(() => {
				state.value.loading = false
				tabLoadStatus.value.auth = true
			})
			break
		case "department":
			getDepartmentList().finally(() => {
				state.value.loading = false
				tabLoadStatus.value.department = true
			})
			break
		case "settlementBu":
			getSettlementBuList().finally(() => {
				state.value.loading = false
				tabLoadStatus.value.settlementBu = true
			})
			break
		case "warehouse":
			getWarehouseList().finally(() => {
				state.value.loading = false
				tabLoadStatus.value.warehouse = true
			})
			break
		case "role":
			getRoleList().finally(() => {
				state.value.loading = false
				tabLoadStatus.value.role = true
			})
			break
		case "supplier":
			getSupplierList().finally(() => {
				state.value.loading = false
				tabLoadStatus.value.supplier = true
			})
			break
	}
}

const onSelectChange = (value) => {
	//console.log(value)
	selectedRowKeys.value = value
	config.value.forEach((item) => {
		if (String(item.merchantAppId) === String(appInfo.value.id)) {
			item.userRoleList = value.map((item) => {
				return {
					roleId: item,
				}
			})
		}
	})
}
const saveLoading = ref(false)
const save = async () => {
	try {
		//console.log(config.value)
		const arr = JSON.parse(JSON.stringify(config.value)).filter(
			(item) => String(item.merchantAppId) === String(appInfo.value.id)
		)
		arr.forEach((item) => {
			item.userResourceList = [
				...(item.allFunKeys || []),
				...(item.allMenuKeys || []),
				...(item.pdaAllMenuKeys || []),
			].map((item) => {
				return {
					resourceId: item,
				}
			})

			item.userFunList = undefined
			item.userMenuList = undefined
			item.pdaUserMenuList = undefined
			item.funList = undefined
			item.menuList = undefined
			item.pdaMenuList = undefined
			item.allMenuKeys = undefined
			item.pdaAllMenuKeys = undefined
			item.allFunKeys = undefined
		})

		departmentTree.value.departmentTreeList.forEach((item) => {
			arr.forEach((otem) => {
				if (otem.userDepartmentList.length > 0) {
					otem.userDepartmentList.forEach((mtem) => {
						if (mtem.departmentId === item.key) {
							item.shopList.forEach((iShop) => {
								mtem.shopUniqueVOList.forEach((mShop) => {
									if (iShop.uniqueCode === mShop.shopCode) {
										mShop.code = iShop.code
									}
								})
							})
							mtem.allShop = item.shopList.length === mtem.shopUniqueVOList.length
						}
					})
				}
			})
		})
		settlementDepartmentTree.value.departmentTreeList.forEach((item) => {
			arr.forEach((otem) => {
				if (otem.userSettlementDepartmentList.length > 0) {
					otem.userSettlementDepartmentList.forEach((mtem) => {
						if (mtem.departmentId === item.key) {
							item.shopList.forEach((iShop) => {
								mtem.shopUniqueVOList.forEach((mShop) => {
									if (iShop.uniqueCode === mShop.shopCode) {
										mShop.code = iShop.code
									}
								})
							})
							mtem.allShop = item.shopList.length === mtem.shopUniqueVOList.length
						}
					})
				}
			})
		})
		arr.forEach((otem) => {
			otem.userDepartmentList.forEach((mtem) => {
				mtem.shopUniqueVOList.forEach((mShop) => {
					mShop.shopCode = mShop.code
				})
			})
			otem.userSettlementDepartmentList.forEach((mtem) => {
				mtem.shopUniqueVOList.forEach((mShop) => {
					mShop.shopCode = mShop.code
				})
			})
		})
		warehouseTree.value.warehouseTreeList.forEach((item) => {
			arr.forEach((otem) => {
				if (otem.userWarehouseList.length > 0) {
					otem.userWarehouseList.forEach((mtem) => {
						if (mtem.warehouseCode === item.code) {
							//console.log(item.shipperList.length, mtem.shipperCodeList.length)
							mtem.allShipper = item.shipperList.length === mtem.shipperCodeList.length
						}
					})
				}
			})
		})
		const data = {
			configList: [...arr],
		}
		saveLoading.value = true
		await axios.post(api.saveConfig, data)
		message.success("保存成功")
		setAccount({ ...appInfo.value })
	} catch (error) {
		console.error("Failed to save:", error)
		message.error("保存失败")
	} finally {
		saveLoading.value = false
	}
}

const backUp = () => {
	router.push({
		name: "accountManage",
	})
}

const config = ref([
	{
		merchantId: store.getters["user/merchantId"],
		userId: userId.value,
		merchantAppId: "",
		userDepartmentList: [{ departmentId: "", departmentType: "2", shopUniqueVOList: [] }],
		userSettlementDepartmentList: [{ departmentId: "", departmentType: "2", shopUniqueVOList: [] }],
		userWarehouseList: [{ warehouseCode: "", wmsAppCode: "", shipperCodeList: [] }],
		userAuthType: "ROLE",
		userResourceList: [{ resourceId: "" }],
		userRoleList: [{ roleId: "" }],
	},
])
//console.log(config.value)

//仓库树结构的数据
const warehouseTree = ref({
	menuExpandedKeys: [],
	menuCheckedKeys: [],
	warehouseTreeList: [],
	menuHalfKeys: [],
})
const warehouseFieldNames = ref({
	children: "shipperList",
	title: "name",
})
const getWarehouseList = async () => {
	try {
		const data = {
			merchantCode: store.getters["user/merchantCode"],
			appCode: "ULTRA_WMS",
			needShipper: true,
		}
		const res = await axios.post(api.queryWarehousePage, data)
		res.list.forEach((item) => {
			item.key = String(item.code)
			if (item.shipperList) {
				item.shipperList.forEach((otem) => {
					otem.key = `${String(item.code)}-${String(otem.code)}`
				})
			}
		})
		warehouseTree.value.warehouseTreeList = res.list
		//console.log(warehouseTree.value.warehouseTreeList)
		config.value.forEach((item) => {
			//console.log(item)
			if (String(item.merchantAppId) === String(appInfo.value.id)) {
				//console.log(item.userWarehouseList)
				item.userWarehouseList.forEach((warehouse) => {
					if (warehouse.shipperCodeList?.length) {
						warehouse.shipperCodeList.forEach((shipperCode) => {
							//console.log(warehouse.warehouseCode)
							warehouseTree.value.menuCheckedKeys.push(`${String(warehouse.warehouseCode)}-${String(shipperCode)}`)
						})
						warehouseTree.value.menuHalfKeys.push(String(warehouse.warehouseCode))
					} else {
						warehouseTree.value.menuCheckedKeys.push(String(warehouse.warehouseCode))
					}
				})
			}
		})
		//console.log(warehouseTree.value.menuCheckedKeys)
	} catch (error) {
		console.error("Failed to get warehouse list:", error)
		message.error("获取仓库列表失败")
	}
}
const setWarehouseKeys = (value, id) => {
	warehouseTree.value.menuHalfKeys = id.halfCheckedKeys
	//console.log(config.value)
	//console.log(value, id)
	//console.log("menuCheckedKeys", warehouseTree.value.menuCheckedKeys)
	//console.log("menuHalfKeys", warehouseTree.value.menuHalfKeys)
	const allKeys = [...new Set([...warehouseTree.value.menuCheckedKeys, ...warehouseTree.value.menuHalfKeys])]
	//console.log(allKeys)
	// allkey 代表所有需要传的Key，包含了父子
	//
	//console.log(allKeys)
	config.value.forEach((item) => {
		if (String(item.merchantAppId) === String(appInfo.value.id)) {
			//console.log("对")
			item.userWarehouseList = []
			warehouseTree.value.warehouseTreeList.forEach((wItem) => {
				if (allKeys.indexOf(wItem.key) > -1) {
					//第一步如果有仓库的id，就生成一个obj
					const obj = {
						warehouseCode: wItem.key,
						wmsAppCode: "ULTRA_WMS",
						shipperCodeList: [],
					}
					//第二步判断是否有货主的id,有的话就添加
					if (wItem.shipperList) {
						wItem.shipperList.forEach((sItem) => {
							if (allKeys.indexOf(sItem.key) > -1) {
								if (sItem.key.indexOf("-") > -1) {
									obj.shipperCodeList.push(sItem.key.split("-")[1])
								} else {
									obj.shipperCodeList.push(sItem.key)
								}
							}
						})
					}
					//完成后添加到config.里面
					//console.log(obj)
					item.userWarehouseList.push(obj)
				}
			})
		}
	})
	//console.log(config.value)
}

//业务组树的数据
const departmentTree = ref({
	expandedKeys: [],
	checkedKeys: [],
	departmentTreeList: [],
	halfKeys: [],
})
const departmentFieldNames = ref({
	children: "shopList",
	title: "name",
})
// 结算组织树的数据
const settlementDepartmentTree = ref({
	expandedKeys: [],
	checkedKeys: [],
	departmentTreeList: [],
	halfKeys: [],
})
// 获取业务组
const getDepartmentList = async () => {
	try {
		const res1 = await axios.post(api.departmentGetRoot, {
			merchantId: store.getters["user/merchantId"],
		})
		const parentId = res1.id
		const res = await axios.post(api.departmentList, {
			merchantId: store.getters["user/merchantId"],
			type: 2,
			parentId: parentId,
			tree: true,
		})
		res.forEach((item) => {
			item.key = String(item.id)
			if (item.shopList) {
				item.shopList.forEach((otem) => {
					otem.key = String(otem.uniqueCode)
				})
			}
		})
		departmentTree.value.departmentTreeList = res
		// 树结构加载出来需要赋值
		config.value.forEach((item) => {
			if (String(item.merchantAppId) === String(appInfo.value.id)) {
				//console.log(item.userDepartmentList)
				item.userDepartmentList.forEach((department) => {
					if (department.shopUniqueVOList?.length) {
						department.shopUniqueVOList.forEach((shopCode) => {
							//console.log(shopCode)
							departmentTree.value.checkedKeys.push(String(shopCode.shopCode))
						})
						departmentTree.value.halfKeys.push(String(department.departmentId))
					} else {
						departmentTree.value.checkedKeys.push(String(department.departmentId))
					}
				})
			}
		})
		//console.log(departmentTree.value.departmentTreeList)
	} catch (error) {
		console.error("Failed to get department list:", error)
		message.error("获取业务组列表失败")
	}
}
// 获取结算组织
const getSettlementBuList = async () => {
	try {
		const res = await axios.post(api.departmentList, {
			merchantId: store.getters["user/merchantId"],
			status: true,
			needFinancialSettlement: true,
		})
		res.forEach((item) => {
			item.key = String(item.id)
			if (item.shopList) {
				item.shopList.forEach((otem) => {
					otem.key = String(otem.uniqueCode)
				})
			}
		})
		settlementDepartmentTree.value.departmentTreeList = res
		// 树结构加载出来需要赋值
		config.value.forEach((item) => {
			//console.log(item)
			if (String(item.merchantAppId) === String(appInfo.value.id)) {
				//console.log(item.userDepartmentList)
				item.userSettlementDepartmentList.forEach((department) => {
					if (department.shopUniqueVOList?.length) {
						department.shopUniqueVOList.forEach((shopCode) => {
							//console.log(shopCode)
							settlementDepartmentTree.value.checkedKeys.push(String(shopCode.shopCode))
						})
						settlementDepartmentTree.value.halfKeys.push(String(department.departmentId))
					} else {
						settlementDepartmentTree.value.checkedKeys.push(String(department.departmentId))
					}
				})
			}
		})
		//console.log(departmentTree.value.departmentTreeList)
	} catch (error) {
		console.error("Failed to get settlement list:", error)
		message.error("获取结算组织列表失败")
	}
}

const setDepartmentKeys = (value, id) => {
	departmentTree.value.halfKeys = id.halfCheckedKeys
	//console.log(value, id)
	//console.log("menuHalfKeys", departmentTree.value)
	const allKeys = [...new Set([...departmentTree.value.checkedKeys, ...departmentTree.value.halfKeys])]
	//console.log(allKeys)
	// allkey 代表所有需要传的Key，包含了父子
	//
	//console.log(allKeys)
	config.value.forEach((item) => {
		if (String(item.merchantAppId) === String(appInfo.value.id)) {
			//找到要操作的应用，先清空勾选的业务组，拿到Allkeys
			item.userDepartmentList = []
			departmentTree.value.departmentTreeList.forEach((wItem) => {
				//console.log(allKeys.indexOf(wItem.key), wItem)
				if (allKeys.indexOf(wItem.key) > -1) {
					//第一步如果有仓库的id，就生成一个obj
					const obj = {
						departmentId: wItem.key,
						departmentType: wItem.type,
						shopUniqueVOList: [],
					}
					//第二步判断是否有货主的id,有的话就添加
					if (wItem.shopList) {
						//console.log(wItem.shopList)
						wItem.shopList.forEach((sItem) => {
							if (allKeys.indexOf(sItem.key) > -1) {
								obj.shopUniqueVOList.push({
									shopCode: String(sItem.key),
									shopName: String(sItem.name),
									platform: sItem.platform,
								})
							}
						})
					}
					//完成后添加到config.里面
					//console.log(obj)
					item.userDepartmentList.push(obj)
				}
			})
		}
	})
	//console.log(config.value)
}
const setSettlementDepartmentKeys = (value, id) => {
	settlementDepartmentTree.value.halfKeys = id.halfCheckedKeys
	//console.log(value, id)
	//console.log("menuHalfKeys", departmentTree.value)
	const allKeys = [
		...new Set([...settlementDepartmentTree.value.checkedKeys, ...settlementDepartmentTree.value.halfKeys]),
	]
	//console.log(allKeys)
	// allkey 代表所有需要传的Key，包含了父子
	//
	//console.log(allKeys)
	config.value.forEach((item) => {
		if (String(item.merchantAppId) === String(appInfo.value.id)) {
			//找到要操作的应用，先清空勾选的业务组，拿到Allkeys
			item.userSettlementDepartmentList = []
			settlementDepartmentTree.value.departmentTreeList.forEach((wItem) => {
				//console.log(allKeys.indexOf(wItem.key), wItem)
				if (allKeys.indexOf(wItem.key) > -1) {
					//第一步如果有仓库的id，就生成一个obj
					const obj = {
						departmentId: wItem.key,
						departmentType: wItem.type,
						shopUniqueVOList: [],
					}
					//第二步判断是否有货主的id,有的话就添加
					if (wItem.shopList) {
						//console.log(wItem.shopList)
						wItem.shopList.forEach((sItem) => {
							if (allKeys.indexOf(sItem.key) > -1) {
								obj.shopUniqueVOList.push({
									shopCode: String(sItem.key),
									shopName: String(sItem.name),
									platform: sItem.platform,
								})
							}
						})
					}
					//完成后添加到config.里面
					//console.log(obj)
					item.userSettlementDepartmentList.push(obj)
				}
			})
		}
	})
	//console.log(config.value)
}

// 功能树结构
const addKey = (value, type) => {
	value.forEach((item) => {
		item.key = String(item.id)
		if (item[type]?.length) {
			addKey(item[type], type)
		}
	})
}

// 菜单树结构
const menuTree = ref({
	expandedKeys: [],
	checkedKeys: [],
	treeList: [],
	halfKeys: [],
})
const pdaMenuTree = ref({
	expandedKeys: [],
	checkedKeys: [],
	treeList: [],
	halfKeys: [],
})
const funTree = ref({
	expandedKeys: [],
	checkedKeys: [],
	treeList: [],
	halfKeys: [],
})
const funFieldNames = ref({
	children: "childrenList",
	title: "name",
})
const getFunList = async () => {
	try {
		const res = await axios.post(api.resourceList, {
			merchantId: store.getters["user/merchantId"],
			authSysCode: appInfo.value.authSysCode,
			name: "",
			status: true,
			all: true, //是否需要全部权限
			resourceTypes: ["FUNC"], //资源类型 MENU菜单 FUNC功能
			tree: true, //是否需要树形结构
			userId: "",
		})
		addKey(res, "childrenList")
		funTree.value.treeList = res
		funTree.value.halfKeys = []
		funTree.value.checkedKeys = []
		//console.log("功能树结构", funTree.value.treeList)
		// 树结构加载出来需要赋值
		config.value.forEach((item) => {
			//console.log(item)
			if (String(item.merchantAppId) === String(appInfo.value.id)) {
				//console.log(item.funList)
				changeFunCheckKeys(funTree.value.treeList, item.allFunKeys || [])
				//console.log(funTree.value)
			}
		})
		// //console.log(departmentTree.value);
	} catch (error) {
		console.error("Failed to get function list:", error)
		message.error("获取功能列表失败")
	}
}
const changeFunCheckKeys = (arr, allkey) => {
	arr.forEach((item) => {
		// //console.log(item.key)
		if (allkey.indexOf(item.key) > -1) {
			if (item.childrenList?.length) {
				changeFunCheckKeys(item.childrenList, allkey)
				funTree.value.halfKeys.push(item.key)
			} else {
				funTree.value.checkedKeys.push(item.key)
			}
		}
	})
}
const setFunCheckKeys = (value) => {
	if (value?.length) {
		value.forEach((item) => {
			if (item.childrenList?.length) {
				funTree.value.halfKeys.push(String(item.id))
				setFunCheckKeys(item.childrenList)
			} else {
				funTree.value.checkedKeys.push(String(item.id))
			}
		})
	}
}
const setFunTreeKeys = (value, id) => {
	//console.log(value, id)
	//console.log(funTree.value.checkedKeys)
	//半节点等于半节点
	funTree.value.halfKeys = id.halfCheckedKeys
	const nowFunKeys = []
	funTree.value.checkedKeys.forEach((item) => {
		if (idMap.value?.length > 0) {
			idMap.value.forEach((t) => {
				if (String(t.menuId) === item) {
					t.funcList.forEach((o) => {
						funTree.value.halfKeys.push(String(o.id))
						nowFunKeys.push(String(o.id))
						o.childrenList.forEach((c) => {
							funTree.value.checkedKeys.push(String(c.id))
							nowFunKeys.push(String(c.id))
						})
					})
				}
			})
		}
	})
	const allKeys = [...new Set([...funTree.value.checkedKeys, ...funTree.value.halfKeys])]
	//console.log(allKeys)
	// allkey 代表所有需要传的Key，包含了父子
	config.value.forEach((item) => {
		if (String(item.merchantAppId) === String(appInfo.value.id)) {
			item.userFunList = allKeys.map((item) => {
				return {
					resourceId: item,
				}
			})
			nowFunKeys.forEach((i) => {
				item.userFunList.push({ resourceId: i })
				item.allFunKeys.push(i)
			})

			item.allFunKeys = allKeys
		}
	})
}
const setMenuTreeKeys = (value, id) => {
	//半节点等于半节点
	// //console.log(value, id);
	menuTree.value.halfKeys = id.halfCheckedKeys
	const nowFunKeys = []
	menuTree.value.checkedKeys.forEach((item) => {
		if (idMap.value?.length > 0) {
			idMap.value.forEach((t) => {
				if (String(t.menuId) === item) {
					t.funcList.forEach((o) => {
						funTree.value.halfKeys.push(String(o.id))
						nowFunKeys.push(String(o.id))
						o.childrenList.forEach((c) => {
							funTree.value.checkedKeys.push(String(c.id))
							nowFunKeys.push(String(c.id))
						})
					})
				}
			})
		}
	})
	const allKeys = [...new Set([...menuTree.value.checkedKeys, ...menuTree.value.halfKeys])]
	//console.log(allKeys)
	// allkey 代表所有需要传的Key，包含了父子
	config.value.forEach((item) => {
		if (String(item.merchantAppId) === String(appInfo.value.id)) {
			item.userMenuList = allKeys.map((item) => {
				return {
					resourceId: item,
				}
			})
			nowFunKeys.forEach((i) => {
				item.userFunList.push({ resourceId: i })
				item.allFunKeys.push(i)
			})
			item.allMenuKeys = allKeys
		}
	})
}
const setPdaMenuTreeKeys = (value, id) => {
	//半节点等于半节点
	// //console.log(value, id);
	pdaMenuTree.value.halfKeys = id.halfCheckedKeys
	const nowFunKeys = []
	pdaMenuTree.value.checkedKeys.forEach((item) => {
		if (idMap.value?.length > 0) {
			idMap.value.forEach((t) => {
				if (String(t.menuId) === item) {
					t.funcList.forEach((o) => {
						funTree.value.halfKeys.push(String(o.id))
						nowFunKeys.push(String(o.id))
						o.childrenList.forEach((c) => {
							funTree.value.checkedKeys.push(String(c.id))
							nowFunKeys.push(String(c.id))
						})
					})
				}
			})
		}
	})
	const allKeys = [...new Set([...pdaMenuTree.value.checkedKeys, ...pdaMenuTree.value.halfKeys])]
	//console.log(allKeys)
	// allkey 代表所有需要传的Key，包含了父子
	config.value.forEach((item) => {
		if (String(item.merchantAppId) === String(appInfo.value.id)) {
			item.userMenuList = allKeys.map((item) => {
				return {
					resourceId: item,
				}
			})
			nowFunKeys.forEach((i) => {
				item.userFunList.push({ resourceId: i })
				item.allFunKeys.push(i)
			})
			item.pdaAllMenuKeys = allKeys
		}
	})
}
const idMap = ref([])
const getResourceMap = async () => {
	try {
		const res = await axios.post(api.getResourceMap, {})
		idMap.value = res
	} catch (error) {
		console.error("Failed to get resource map:", error)
		message.error("获取资源映射失败")
	}
}

const getMenuList = () => {
	axios
		.post(api.resourceList, {
			merchantId: store.getters["user/merchantId"],
			authSysCode: appInfo.value.authSysCode,
			name: "",
			status: true,
			all: true, //是否需要全部权限
			resourceTypes: ["MENU"], //资源类型 MENU菜单 FUNC功能
			tree: true, //是否需要树形结构
			userId: "",
		})
		.then((res) => {
			addKey(res, "childrenList")
			menuTree.value.treeList = []
			pdaMenuTree.value.treeList = []
			res.forEach((item) => {
				if (item.terminal === "PDA") {
					pdaMenuTree.value.treeList.push(item)
				} else {
					menuTree.value.treeList.push(item)
				}
			})
			// //console.log("菜单树结构", menuTree.value.treeList);
			// //console.log("菜单树结构", pdaMenuTree.value.treeList);
			// 树结构加载出来需要赋值
			config.value.forEach((item) => {
				if (String(item.merchantAppId) === String(appInfo.value.id)) {
					//console.log(item.menuList)
					changeMenuCheckKeys(menuTree.value.treeList, item.allMenuKeys)
					changePdaMenuCheckKeys(pdaMenuTree.value.treeList, item.pdaAllMenuKeys)
					// //console.log(menuTree.value);
					// //console.log(pdaMenuTree.value);
				}
			})
			// //console.log(menuTree.value);
			// //console.log(pdaMenuTree.value);
		})
}
const changePdaMenuCheckKeys = (arr, allkey) => {
	// //console.log(arr, allkey);
	arr.forEach((item) => {
		// //console.log(item.key);
		if (allkey.indexOf(item.key) > -1) {
			if (item.childrenList?.length) {
				changePdaMenuCheckKeys(item.childrenList, allkey)
				pdaMenuTree.value.halfKeys.push(item.key)
			} else {
				pdaMenuTree.value.checkedKeys.push(item.key)
			}
		}
	})
}
const changeMenuCheckKeys = (arr, allkey) => {
	// //console.log(arr, allkey);
	arr.forEach((item) => {
		// //console.log(item.key);
		if (allkey.indexOf(item.key) > -1) {
			if (item.childrenList?.length) {
				changeMenuCheckKeys(item.childrenList, allkey)
				menuTree.value.halfKeys.push(item.key)
			} else {
				menuTree.value.checkedKeys.push(item.key)
			}
		}
	})
}
const setMeunCheckKeys = (value) => {
	if (value?.length) {
		value.forEach((item) => {
			if (item.childrenList?.length) {
				menuTree.value.halfKeys.push(String(item.id))
				setMeunCheckKeys(item.childrenList)
			} else {
				menuTree.value.checkedKeys.push(String(item.id))
			}
		})
	}
}
const setPdaMeunCheckKeys = (value) => {
	if (value?.length) {
		value.forEach((item) => {
			if (item.childrenList?.length) {
				pdaMenuTree.value.halfKeys.push(String(item.id))
				setPdaMeunCheckKeys(item.childrenList)
			} else {
				pdaMenuTree.value.checkedKeys.push(String(item.id))
			}
		})
	}
}
</script>

<style lang="scss" scoped>
.auth-account-container {
  .header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left-panel {
    height: calc(100vh - 210px);
    overflow-y: auto;

    .search-bar {
      margin-bottom: 16px;

      :deep(.ant-input-affix-wrapper) {
        border-radius: 4px;
      }
    }

    .account-list {
      .account-item {
        padding: 12px 16px;
        cursor: pointer;
        border-radius: 4px;
        transition: all 0.3s;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          background-color: #f5f5f5;
        }

        &.active {
          background-color: #e6f7ff;
          border-left: 3px solid #1890ff;
        }

        .account-info {
          .account-name {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 4px;
          }

          .account-type {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
  }

  .right-panel {
    height: calc(100vh - 210px);

    .auth-tabs {
      :deep(.ant-tabs-nav) {
        margin-bottom: 16px;
      }
    }

    .footer-actions {
      padding: 16px 0 0;
      text-align: right;
      border-top: 1px solid #f0f0f0;
    }

    .authTreeStyle {
      // padding: 16px;

      .funClass {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
</style>
